import { Component, Vue, Prop } from "vue-property-decorator";
import API from "@api";
import { cloneStructured, declensionOfNouns } from "@helpers";
import { walletModule } from "@store/namespaces";
import { CardBatchUpdate } from "@/types/card";
import { WalletActions } from "@store/modules/wallet/types";

@Component
export default class BatchUpdateCardsProgress extends Vue {
  @Prop({ type: [String, Number], default: "" }) private reloadKey!:
    | string
    | number;

  @walletModule.Action("fetchWallets")
  private readonly fetchWalletsAction!: WalletActions["fetchWallets"];

  private batchUpdateCards: {
    list: Readonly<CardBatchUpdate[]>;
    loading: boolean;
    cancelable: {
      value: null | CardBatchUpdate;
      loading: boolean;
    };
  } = {
    list: [],
    loading: false,
    cancelable: {
      value: null,
      loading: false,
    },
  };

  private get isShowConfirmCancelBatchIssueCards() {
    return !!this.batchUpdateCards.cancelable.value;
  }

  private set isShowConfirmCancelBatchIssueCards(showed) {
    this.batchUpdateCards.cancelable.value = showed
      ? this.batchUpdateCards.cancelable.value
      : null;
  }

  private declensionOfNouns(count: number, words: string[]) {
    return declensionOfNouns(count, words);
  }

  private toggleConfirmCancelBatchIssueCard(
    showed = !this.isShowConfirmCancelBatchIssueCards
  ) {
    this.isShowConfirmCancelBatchIssueCards = showed;
  }

  private onCancelBatchIssueCard(item: CardBatchUpdate) {
    this.batchUpdateCards.cancelable.value = cloneStructured(item);
  }

  private async cancelBatchIssueCard() {
    if (
      this.batchUpdateCards.cancelable.loading ||
      !this.batchUpdateCards.cancelable.value
    ) {
      return;
    }

    this.batchUpdateCards.cancelable.loading = true;

    const { id } = this.batchUpdateCards.cancelable.value;

    try {
      await API.card.batchUpdateCardEdit({
        id,
        isCanceled: true,
      });
      await this.fetchBatchIssueCardList();
      await this.fetchWalletsAction();

      this.batchUpdateCards.cancelable.loading = false;
      this.toggleConfirmCancelBatchIssueCard(false);
    } catch {
      this.batchUpdateCards.cancelable.loading = false;
    }
  }

  private getPercentProgress({
    completed,
    updatedCardIds,
    cardIds,
  }: CardBatchUpdate) {
    if (completed) {
      return 100;
    }

    return Math.round((updatedCardIds.length * 100) / cardIds.length);
  }

  private async fetchBatchIssueCardList() {
    if (this.batchUpdateCards.loading) return;

    this.batchUpdateCards.loading = true;

    try {
      const batchUpdateCards = await API.card.fetchBatchUpdateCardList();

      this.batchUpdateCards.list = Object.freeze(
        batchUpdateCards.filter(
          ({ closedAt, canceledAt }) => !closedAt && !canceledAt
        )
      );
      this.batchUpdateCards.loading = false;
    } catch (error) {
      this.batchUpdateCards.loading = false;
      this.batchUpdateCards.list = [];
    }
  }

  private initBatchIssueCardList() {
    let intervalId = 0;

    this.$watch(
      () => {
        return this.reloadKey;
      },
      () => {
        this.fetchBatchIssueCardList();

        window.clearInterval(intervalId);

        intervalId = window.setInterval(async () => {
          if (this.batchUpdateCards.loading) return;

          await this.fetchBatchIssueCardList();
          await this.$nextTick();

          if (!this.batchUpdateCards.list.length) {
            window.clearInterval(intervalId);
          }
        }, 5e3);
      },
      { immediate: true }
    );

    const timeoutIds: Record<string, number> = {};

    this.$watch(
      () => {
        return this.batchUpdateCards.list
          .filter(({ completed }) => completed)
          .map(({ id }) => id);
      },
      (completedIds) => {
        completedIds.forEach((completedId) => {
          if (timeoutIds[completedId] > -1) return;

          timeoutIds[completedId] = 0;

          API.card
            .batchUpdateCardEdit({
              id: completedId,
              isClosed: true,
            })
            .finally(() => {
              timeoutIds[completedId] = window.setTimeout(() => {
                this.batchUpdateCards.list = Object.freeze(
                  this.batchUpdateCards.list.filter(
                    ({ id }) => id !== completedId
                  )
                );

                this.$emit("completed");

                delete timeoutIds[completedId];
              }, 2e3);
            });
        });
      }
    );

    this.$watch(
      () => {
        return this.batchUpdateCards.list.reduce<string>(
          (str, { updatedCardIds, id }) => {
            return (str += `${id}_${updatedCardIds.length}_`);
          },
          ""
        );
      },
      () => {
        this.$emit("updated");
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearInterval(intervalId);
      Object.values(timeoutIds).forEach((timeoutId) => {
        window.clearTimeout(timeoutId);
      });
    });
  }

  private mounted() {
    this.initBatchIssueCardList();
  }
}
