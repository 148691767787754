var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-expand-transition',[(_vm.batchUpdateCards.list.length > 0)?_c('section',_vm._l((_vm.batchUpdateCards.list),function(item){return _c('v-expand-transition',{key:item.id},[_c('div',[(_vm.$vuetify.breakpoint.mobile)?_c('header',{staticClass:"d-flex align-center font-weight-medium blue-grey--text text--darken-4 text-caption mb-2"},[(!item.completed)?_c('LottieBanner',{style:({
                marginRight: '-2px',
                marginLeft: '-10px',
                marginTop: '-10px',
                marginBottom: '-10px',
              }),attrs:{"src":"/img/lottie/hourglass.json","aspect-ratio":"1","max-width":"33"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16","color":"blue-grey darken-4"}},[_vm._v(" $checkmark ")]),(item.completed)?_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.$vuetify.lang.t(
                  `$vuetify.dashboard.card.batch_update.success.${item.cardAction.toLowerCase()}_cards`,
                  item.updatedCardIds.length,
                  _vm.declensionOfNouns(item.updatedCardIds.length, [
                    _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_1'),
                    _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_2'),
                    _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_3'),
                  ]),
                  _vm.declensionOfNouns(item.updatedCardIds.length, [
                    _vm.$vuetify.lang.t(
                      `$vuetify.info.declension.cards.${item.cardAction.toLowerCase()}.i_1`
                    ),
                    _vm.$vuetify.lang.t(
                      `$vuetify.info.declension.cards.${item.cardAction.toLowerCase()}.i_2`
                    ),
                    _vm.$vuetify.lang.t(
                      `$vuetify.info.declension.cards.${item.cardAction.toLowerCase()}.i_3`
                    ),
                  ])
                )
              )}}):_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.$vuetify.lang.t(
                  `$vuetify.dashboard.card.batch_update.pending.${item.cardAction.toLowerCase()}_cards`,
                  item.updatedCardIds.length,
                  item.cardIds.length
                )
              )}})],1):_vm._e(),_c('div',{class:[
              'd-flex',
              {
                'pb-6': !_vm.$vuetify.breakpoint.mobile,
                'pb-4': _vm.$vuetify.breakpoint.mobile,
              },
            ]},[_c('v-progress-linear',{class:[
                'progressbar',
                'flex-grow-1',
                {
                  progressbar_pending: !item.completed,
                  progressbar_success: item.completed,
                },
              ],attrs:{"value":_vm.getPercentProgress(item),"rounded":"","height":"40","color":item.completed ? '#45DF83' : '#FFC008'}},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{class:[
                  'flex-grow-1',
                  'd-flex',
                  'align-center',
                  'pr-6',
                  'font-weight-medium',
                  {
                    'blue-grey--text text--darken-4': !item.completed,
                    'white--text': item.completed,
                    'pl-6': item.completed,
                    'pl-2': !item.completed,
                  },
                ]},[(!item.completed)?_c('LottieBanner',{style:({ marginRight: '-4px' }),attrs:{"src":"/img/lottie/hourglass.json","aspect-ratio":"1","max-width":"43"}}):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"white"}},[_vm._v(" $checkmark ")]),(item.completed)?_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                    _vm.$vuetify.lang.t(
                      `$vuetify.dashboard.card.batch_update.success.${item.cardAction.toLowerCase()}_cards`,
                      item.updatedCardIds.length,
                      _vm.declensionOfNouns(item.updatedCardIds.length, [
                        _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_1'),
                        _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_2'),
                        _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_3'),
                      ]),
                      _vm.declensionOfNouns(item.updatedCardIds.length, [
                        _vm.$vuetify.lang.t(
                          `$vuetify.info.declension.cards.${item.cardAction.toLowerCase()}.i_1`
                        ),
                        _vm.$vuetify.lang.t(
                          `$vuetify.info.declension.cards.${item.cardAction.toLowerCase()}.i_2`
                        ),
                        _vm.$vuetify.lang.t(
                          `$vuetify.info.declension.cards.${item.cardAction.toLowerCase()}.i_3`
                        ),
                      ])
                    )
                  )}}):_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                    _vm.$vuetify.lang.t(
                      `$vuetify.dashboard.card.batch_update.pending.${item.cardAction.toLowerCase()}_cards`,
                      item.updatedCardIds.length,
                      item.cardIds.length
                    )
                  )}})],1):_vm._e(),_c('strong',{class:[
                  'progressbar__percent',
                  {
                    'blue-grey--text text--darken-4': !item.completed,
                    'white--text': item.completed,
                  },
                ]},[_vm._v(" "+_vm._s(_vm.getPercentProgress(item))+"% ")])]),(!item.completed)?_c('v-btn',{staticClass:"ml-2 flex-shrink-0",attrs:{"color":"error","height":"40"},on:{"click":function($event){return _vm.onCancelBatchIssueCard(item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.cancel"))+" ")]):_vm._e()],1)])])}),1):_vm._e()]),_c('ConfirmDialog',{attrs:{"loading":_vm.batchUpdateCards.cancelable.loading,"max-width":440},on:{"confirm":_vm.cancelBatchIssueCard},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.t(
            '$vuetify.dashboard.card.batch_update.are_you_sure.cancel_action'
          )
        )}})]},proxy:true}]),model:{value:(_vm.isShowConfirmCancelBatchIssueCards),callback:function ($$v) {_vm.isShowConfirmCancelBatchIssueCards=$$v},expression:"isShowConfirmCancelBatchIssueCards"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }